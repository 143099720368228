<template>
  <!-- 注册挽留弹窗 -->
  <el-dialog
    :visible.sync="showDialog"
    custom-class="register-dialog"
    append-to-body
    width="436px"
    :show-close="false"
    :before-close="close"
  >
    <img class="tips-icon" :src="icon" alt="" />
    <header :style="'background-image:url(' + bg + ')'">
      <div class="head-title">您已获得免费使用特权</div>
      <div class="head-tips">上千位活动操盘手使用活动阁实现10亿+业绩目标</div>
      <div class="head-tips">上万商家用活动阁把营销效率提升60%以上</div>
      <div class="head-tips">80%的策划/运营者在活动阁获得了营销玩法灵感</div>
    </header>
    <footer>
      <div class="btn-item" @click="$emit('back')">仍然放弃</div>
      <div class="btn-item submit" @click="close">继续注册</div>
    </footer>
  </el-dialog>
</template>

<script>
import { loginUrl } from '../../../utils/imagesUrl'
export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  watch: {
    show(n, o) {
      console.log(n)
      console.log('123')
      this.showDialog = n
    },
  },
  data() {
    return {
      showDialog: false,
      icon: loginUrl.login_register_tips, //注册挽留表情图标
      bg: loginUrl.login_register_tips_bg, //注册挽留背景
    }
  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.tips-icon {
  position: absolute;
  width: 205px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  top: -78px;
}
header {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  background-size: 100% 100%;
  background-position: 0 0;
  padding-bottom: 22px;

  .head-title {
    color: #fff;
    font-size: 29px;
    padding-bottom: 20px;
    line-height: 29px;
  }
  .head-tips {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
  }
}
footer {
  padding: 38px 0 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-item {
    width: 184px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    border-radius: 20px;
    border: 1px solid #409eff;
    color: #409eff;
    font-size: 14px;
    cursor: pointer;
  }
  .submit {
    background-color: #409eff;
    color: #fff;
    margin-left: 7px;
  }
}
</style>

<style lang="scss">
.register-dialog {
  border-radius: 5px;
  position: relative;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__footer {
    padding: 0;
  }
}
</style>
