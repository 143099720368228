<template>
	<!-- 左侧展示图 -->
	<div class="LeftImg">
		<img class="img-main" :key="img" :src="img" />
	</div>
</template>

<script>
import { loginUrl } from '../../../utils/imagesUrl';
export default {
	name: '',
	data() {
		return {
			img: loginUrl.login_poster,
		};
	},
	created() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
.LeftImg {
	padding: 8px;
	height: 100%;
	box-sizing: border-box;
	.img-main {
		width: 320px;
		height: 100%;
		display: block;
		border-radius: 12px;
		object-fit: cover;
	}
}
</style>
