<template>
  <!-- 密码登录 -->
  <div class="wechatlogin">
    <div class="wechat" @click="$emit('update:status', oldStatus == 2 ? 2 : 1)">
      <span class="wechat-icon"
        ><span class="login-icon iconfont icon-diannao"></span
      ></span>
      <div class="wechat-icon-shelter"></div>
      <div class="wechat-tips">
        <div class="wechat-border">
          {{ oldStatus == 2 ? '验证码登录' : '账号密码登录' }}
        </div>
      </div>
    </div>
    <div class="main">
      <div class="title">微信扫一扫登录</div>

      <div class="main-qr">
        <div class="mask" :style="overdue ? 'opacity:1' : 'opacity:0'">
          <span
            class="mask-icon iconfont icon-shuaxin"
            :class="loading ? 'tips-loading' : ''"
            @click="getQr"
          ></span>
        </div>
        <img
          class="qr-img"
          v-if="qrInfo.url"
          :src="qrInfo.url"
          :key="qrInfo.url"
          alt=""
        />
        <span class="qr-border left-top iconfont icon-biankuang"></span>
        <span class="qr-border left-bottom iconfont icon-biankuang"></span>
        <span class="qr-border right-top iconfont icon-biankuang"></span>
        <span class="qr-border right-bottom iconfont icon-biankuang"></span>
      </div>

      <div class="treaty">
        登录即视为同意<span class="treaty-main" @click="goAgreement"
          >《活动阁平台使用协议》</span
        >
      </div>
      <div class="bottom">
        没有账号?
        <span class="register" @click="$emit('update:status', 4)"
          >立即注册</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginQrCode, getScanQrcode } from '@/api/index'
import SvgIcon from '@/components/SvgIcon/index'

export default {
  name: '',
  props: {
    status: Number,
    oldStatus: Number
  },
  components: {
    SvgIcon
  },
  // 卸载前
  beforeUnmount() {
    clearInterval(this.time)
  },
  data() {
    return {
      overdue: false, //true 二维码过期
      loading: false, // true 过期获取二维码loading
      qrInfo: '', //二维码信息
      count: '', //获取二维码时间
      time: '' //轮询计时器
    }
  },
  created() {
    this.getQr()
  },
  methods: {
    // 获取验证码
    async getQr() {
      this.loading = true
      try {
        let res = await getLoginQrCode()
        this.count = new Date().getTime()
        this.qrInfo = res.data
        this.overdue = false
        this.loading = false
        this.queryQr()
      } catch (error) {
        this.loading = false
      }
    },
    async goAgreement() {
      let url = await this.$store.state['common'].domainList.www.url
      window.open(`${url}/vuemerchant/#/agreement`)
    },
    // 轮询检测
    async queryQr() {
      if (this.time) clearInterval(this.time)
      this.time = setInterval(async () => {
        // 检测失效
        let date = new Date().getTime()
        let unit = 9 * 60 * 1000 //过期时间10分钟,提前一分钟显示失效
        if (date - this.count >= unit) {
          this.overdue = true
          clearInterval(this.time)
          return
        }
        //轮询
        let res = await getScanQrcode({
          rand_key: this.qrInfo.rand_key
        })
        if (res.status) {
          this.getQr()
          clearInterval(this.time)
          return
        }
        if (res.data.code === 0) {
          clearInterval(this.time)
          // 账号已存在商户
          if (res.data.cache.merchant_id && res.data.cache.token) {
            this.$message.success('登录成功')
            this.$store.dispatch('merchant/wechatLogin', res)
          } else {
            this.$message.success('扫码成功')
            // 新用户-绑定手机号
            this.$emit('binding', res.data.cache)
          }
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  background: none;
  outline: none;
  border: none;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #82859f;
  line-height: 20px;
  font-size: 14px;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #82859f;
  line-height: 20px;
  font-size: 14px;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #82859f;
  line-height: 20px;
  font-size: 14px;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #82859f;
  line-height: 20px;
  font-size: 14px;
}
.wechatlogin {
  padding-top: 56px;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  .wechat {
    position: absolute;
    right: 0;
    top: 0;
    width: 88px;
    height: 88px;
    cursor: pointer;
    &:hover {
      .wechat-icon {
        color: #33aaff;
      }
      .wechat-tips {
        opacity: 1;
      }
    }
    .wechat-icon {
      background-color: #f0f3f5;
      border-radius: 0 10px 0 0;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 90px;
      font-size: 30px;
      overflow: hidden;
      color: #72838f;
      transition: color 0.2s;
      .login-icon {
        font-size: 64px;
      }
    }

    .wechat-icon-shelter {
      position: absolute;
      left: -1px;
      top: 0;
      width: 0;
      height: 0;
      border-bottom: 90px solid #fff;
      border-right: 90px solid transparent;
      z-index: 1;
    }
    .wechat-tips {
      position: absolute;
      left: -82px;
      top: 11px;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s;
    }

    .wechat-border {
      width: 88px;
      height: 34px;
      border-radius: 6px;
      background-color: #33aaff;
      position: relative;
      color: #fff;
      text-align: center;
      line-height: 34px;
      font-size: 12px;
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        right: -14px;
        transform: translateY(-50%);
        content: '';
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left: 8px solid #33aaff;
      }
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 24px;
      color: #0e1234;
      font-weight: 700;
      position: relative;
    }

    .main-qr {
      width: 200px;
      height: 200px;
      border-radius: 8px;
      border: 1px solid #d5d9db;
      padding: 2px;
      box-sizing: border-box;
      margin-top: 28px;
      position: relative;
      .qr-img {
        width: 100%;
        height: 100%;
        display: block;
      }
      .mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.95);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        .mask-icon {
          font-size: 25px;
          cursor: pointer;
          color: #fff;
          width: 48px;
          height: 48px;
          border-radius: 50px;
          background-color: #33aaff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .tips-loading {
          animation: rotate 3s linear;
          animation-iteration-count: infinite;
        }
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .mask-tips {
          margin-top: 20px;
          font-size: 15px;
        }
      }
      .qr-border {
        position: absolute;

        color: #33aaff;
        font-size: 30px;
      }
      .left-top {
        left: -2px;
        top: -2px;
      }
      .left-bottom {
        left: -2px;
        bottom: -2px;
        transform: rotate(-90deg);
      }
      .right-top {
        right: -2px;
        top: -2px;
        transform: rotate(90deg);
      }
      .right-bottom {
        right: -2px;
        bottom: -2px;
        transform: rotate(180deg);
      }
    }
    .qr-tips {
      display: flex;
      align-items: center;
      padding-top: 20px;
      .tips-icon {
        width: 24px;
        height: 24px;
        background: #3fcf77;
        color: #fff;
        text-align: center;
        line-height: 24px;
        border-radius: 2px;
      }
      .tips-text {
        padding-left: 10px;
        color: #0e1234;
      }
    }

    .treaty {
      font-size: 12px;
      color: #aebac2;
      line-height: 12px;
      padding-top: 32px;

      .treaty-main {
        color: #72838f;
        cursor: pointer;
        font-weight: bold;
      }
    }
    .bottom {
      padding-top: 99px;
      color: #72838f;
      text-align: center;
      .register {
        height: 24px;
        padding: 0 6px;
        line-height: 24px;
        display: inline-block;
        border-radius: 2px;
        color: #33aaff;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
</style>
