<template>
	<!-- 绑定手机号 -->
	<div class="codeLogin">
		<div class="main">
			<span class="title">绑定手机号</span>
			<div class="input-item">
				<span class="item-icon iconfont icon-shoujihaoma"></span>
				<input
					class="input"
					type="text"
					placeholder="请输入手机号"
					v-model="phone"
					autocomplete="off"
					@input="edit('phone')"
					@keyup.enter="inputSubmit"
					:maxlength="11"
					@focus="changeClass($event, 'add')"
					@blur="changeClass($event, 'del')"
				/>
			</div>
			<div class="code">
				<div class="input-item code-input">
					<span class="item-icon iconfont icon-yanzheng"></span>
					<input
						class="input"
						type="text"
						placeholder="验证码"
						v-model="code"
						autocomplete="off"
						@input="edit('code')"
						@keyup.enter="inputSubmit"
						:maxlength="4"
						@focus="changeClass($event, 'add')"
						@blur="changeClass($event, 'del')"
					/>
				</div>
				<el-button
					class="code-btn"
					:class="phone && phone.length == 11 ? '' : 'input-default'"
					type="primary"
					round
					v-show="count <= 0"
					:loading="loading"
					@click="getCode"
				>
					<span>{{ loading ? '' : '获取验证码' }}</span>
				</el-button>
				<div class="code-default" v-show="count > 0">已发送 ({{ count }}S)</div>
			</div>
			<div class="input-item">
				<span class="item-icon iconfont icon-mima2"></span>
				<input
					class="input"
					:type="passwordType ? 'password' : 'text'"
					placeholder="请设置6-15位密码"
					v-model="password"
					:autocomplete="passwordType ? 'new-password' : 'off'"
					@keyup.enter="inputSubmit"
					:maxlength="16"
					@focus="changeClass($event, 'add')"
					@blur="changeClass($event, 'del')"
				/>
				<span class="input-icon" v-show="password" @click="passwordType = !passwordType">
					<span class="password-icon iconfont icon-yincang" v-show="passwordType"></span>
					<span class="password-icon iconfont icon-xianshi" v-show="!passwordType"></span>
				</span>
			</div>

			<el-button class="login" v-show="code && phone && phone.length == 11" :loading="submitLoading" @click="submit">立即绑定</el-button>
			<div class="login login-disable" v-show="!code || !phone || phone.length != 11" @click="submit">立即绑定</div>
		</div>
	</div>
</template>

<script>
import { checkTel } from '@/filters/index';
import SvgIcon from '@/components/SvgIcon/index';
import { removeStore } from '@/storage/index';
import { WECHAT_INFO } from '@/storage/variable';
export default {
	name: '',
	props: {
		status: Number,
		wechatInfo: Object,
	},
	components: {
		SvgIcon,
	},
	data() {
		return {
			phone: '',
			code: '', //验证码
			password: '', //密码
			passwordType: true, //false 明文 true 密文
			loading: false, //获取验证码loading
			count: 0, //倒计时秒
			codeTime: '', //倒计时计时器
			submitLoading: false,
		};
	},
	methods: {
		edit(type) {
			if (type == 'phone') {
				this.phone = this.phone.replace(/[^\d]/g, '');
			}
			if (type == 'code') {
				this.code = this.code.replace(/[^\d]/g, '');
			}
		},
		changeClass(e, type) {
			if (type == 'add') {
				e.currentTarget.parentElement.classList.add('input-focus');
			} else {
				e.currentTarget.parentElement.classList.remove('input-focus');
			}
		},
		// 输入框按下回车
		inputSubmit() {
			if (!this.phone || !this.code || !this.password) return;
			this.submit();
		},
		// 获取验证码
		async getCode() {
			if (!this.phone || !checkTel(this.phone)) return this.$message.warning('请输入正确的手机号');
			if (this.count) return;
			// 验证腾讯防水墙
			const res = await this.$store.dispatch('common/getTencentCheck');
			if (res.ret) return;
			this.loading = true;
			try {
				// 获取验证码
				const result = await this.$store.dispatch('common/getCode', {
					number: this.phone,
					cms_type: 0,
					ticket: res.ticket,
					rand_str: res.randstr,
				});
				this.count = 0;
				if (this.codeTime) clearInterval(this.codeTime);
				this.count = 60;
				this.codeTime = setInterval(() => {
					this.count = this.count - 1;
					if (this.count <= 0) {
						clearInterval(this.codeTime);
					}
				}, 1000);
				this.loading = false;
			} catch (error) {
				this.loading = false;
			}
		},
		// 提交
		async submit() {
			if (!checkTel(this.phone)) return this.$message.warning('请输入正确的手机号');
			if (!this.code || this.code.length < 4) return this.$message.warning('请输入正确的验证码');
			if (!this.password) return this.$message.warning('请输入需要设置的密码');
			this.submitLoading = true;
			try {
				const params = {
					mobile: this.phone,
					code: this.code,
					password: this.password,
					union_id: this.wechatInfo.union_id || '',
					source: 'creator',
				};
				const result = await this.$store.dispatch('merchant/userRegister', params);
				if (result.status == 0) {
					removeStore(WECHAT_INFO);
					this.$store.commit('merchant/SET', {
						wechat_info: '',
					});
					this.$store.commit('merchant/SET', {
						isShowLogin: false,
					});
					this.$emit('success');
					location.reload();
					this.$message.success('绑定成功');
				}
				this.submitLoading = false;
			} catch (error) {
				this.submitLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
input {
	background: none;
	outline: none;
	border: none;
}

input::placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #aebac2;
	line-height: 20px;
	font-size: 14px;
}
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.input-focus {
	border: 1px solid #33aaff !important;
	box-shadow: 0px 0px 0px 2px #ebf7ff;
}
.input-default {
	background: #adddff !important;
	border: 1px solid #adddff !important;
}
.codeLogin {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding-top: 56px;

	.main {
		display: flex;
		flex-direction: column;
		width: 318px;
		.title {
			font-size: 24px;
			color: #0e1234;
			text-align: center;
			font-weight: bold;
		}

		.input-item {
			margin-top: 24px;
			height: 44px;
			overflow: hidden;
			line-height: 44px;
			background: #ffffff;
			border-radius: 6px;
			padding: 10px 0;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			border: 1px solid #cbcbcb;
			position: relative;
			transition: all 0.3s;
			&:hover {
				border: 1px solid #33aaff;
			}

			.item-icon {
				width: 40px;
				flex-shrink: 0;
				text-align: center;
				font-size: 16px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #333;
				&::after {
					content: '';
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					display: block;
					height: 16px;
					border: 1px solid #d8d8d8;
				}
				.item-icon-svg {
					width: 14px;
					height: 14px;
				}
			}
			.input {
				width: 100%;
				height: 100%;
				padding: 20px;
			}
			.input-icon {
				padding-right: 20px;
				height: 44px;
				line-height: 44px;
				display: inline-block;
				text-align: left;
				cursor: pointer;
				.password-icon {
					font-size: 16px;
					color: #333;
				}
			}
		}
		.code {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 24px;
			.code-input {
				margin-top: 0;
				margin-right: 8px;
			}
			.code-btn {
				padding: 14px 19px;
				width: 108px;
				height: 44px;
				border-radius: 6px;
				background-color: #33aaff;
				color: #f7f9fa;
				&:hover {
					background-color: #248bf2;
				}
				&:active {
					background-color: #176de6;
				}
				/deep/.el-icon-loading {
					margin-left: 6px;
				}
			}
			.code-default {
				width: 110px;
				height: 44px;
				border-radius: 6px;
				background-color: #adddff;
				color: #f7f9fa;
				text-align: center;
				line-height: 44px;
			}
		}
		.login {
			margin-top: 32px;
			height: 44px;
			background: #33aaff;
			border-radius: 6px;
			text-align: center;
			color: #f7f9fa;
			font-size: 16px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			border:none;
			&:hover {
				background-color: #248bf2;
			}
			&:active {
				background-color: #176de6;
			}
		}
		.login-disable {
			color: #f7f9fa;
			background: #adddff !important;
		}

		.bottom {
			padding-top: 86px;
			color: #72838f;
			text-align: center;
			.register {
				height: 24px;
				padding: 0 6px;
				line-height: 24px;
				display: inline-block;
				border-radius: 2px;
				color: #33aaff;
				cursor: pointer;
				font-weight: bold;
			}
		}
	}
}
</style>
