<template>
  <div class="cont">
    <div class="pro_topbar">
      <div class="page">
        <div class="fl">
          <el-link class="fl-logo" :underline="false" href="/">
            <h1>活动阁</h1>
          </el-link>
          <ul>
            <li>
              <router-link tag="el-link" to="/">首页</router-link>
            </li>
            <li>
              <el-link @click="goAmount">解决方案</el-link>
            </li>
            <li>
              <router-link tag="el-link" to="/case">案例场景</router-link>
            </li>
            <li>
              <el-link target="_blank" href="http://partner.huodongge.cn"
                >渠道合作</el-link
              >
            </li>
            <li>
              <el-link @click="goMy">关于我们</el-link>
            </li>
          </ul>
        </div>
        <!-- v-if="!token" -->
        <div class="fr">
          <span @click="registerShow">免费注册</span>
          <span @click="loginShow">登录</span>
        </div>
        <!-- <div class="user" v-else>
          <img class="user-head" :src="merchantInfo.head_img || img" />
          <div class="user-phone">
            {{ merchantInfo.mobile }}
            <i
              style="padding-left: 10px; font-weight: 500"
              class="el-icon-arrow-down"
            ></i>
          </div>
          <div class="user-list">
            <div class="user-list-item" @click="login">进入后台</div>
            <div class="user-list-item logout" @click="logout">
              <span>退出</span>
              <i class="el-icon-switch-button"></i>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <Login />
  </div>
</template>
<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import { getMerchantLoginOut } from '@/api/index'
import Login from '@/components/login/index'
export default {
  props: {
    wwwUrl: {
      type: String,
      default: 'https://www.huodongge.cn',
    },
  },
  components: {
    Login,
  },
  data() {
    return {
      img: require('../assets/head_img.png'),
      // token
      token: Cookies.get('token') || '',
      // 是否为注册
      is_register: false,
      // 商户信息
      merchantInfo: JSON.parse(localStorage.getItem('merchantInfo')) || {},
    }
  },
  methods: {
    goAmount() {
      this.$emit('goAmount')
    },
    goMy() {
      this.$emit('goMy')
    },
    /**
     * @title 进入后台
     */
    // login() {
    //   const token = Cookies.get('token')
    //   const shop_id = Cookies.get('shop_id')

    //   if (token && shop_id) {
    //     return (window.location.href = this.wwwUrl + '/vuemerchant/#/analysis')
    //   } else if (token) {
    //     return (window.location.href = this.wwwUrl + '/vuemerchant/#/account')
    //   }
    // },
    loginShow() {
      this.$store.dispatch('merchant/showLogin')
    },
    registerShow() {
      this.$store.dispatch('merchant/showRegister')
    },
    /**
     * @title 退出登录
     */
    // logout() {
    //   this.$confirm('确认退出吗?', '提示', {}).then(async () => {
    //     // 发起请求 清除老项目缓存
    //     const result = await axios.get(this.wwwUrl + '/mockLogin/login_out')
    //     const data = await getMerchantLoginOut()
    //     console.log(result)
    //     // 发起请求 清除老项目redis
    //     if (result.data.status == 0) {
    //       Cookies.remove('token')
    //       Cookies.remove('shop_id')
    //       localStorage.removeItem('merchantInfo')
    //       window.location.href = this.wwwUrl + '/vuemerchant/#/user/login'
    //     }
    //   })
    // }
  },
}
</script>
<style lang="scss" scoped>
.pro_topbar {
  width: 100%;
  height: 70px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
}
.pro_topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.9);
}

.page {
  max-width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
  .fl-logo {
    width: 195px;
    height: 70px;
    background: url('../assets/logo.png') no-repeat 100% 100%/100% 100%;
    float: left;
    text-indent: -999px;
    overflow: hidden;
  }
}

.page h1 {
}

.page ul {
  float: left;
  font-size: 16px;
}

.page li {
  float: left;
  margin-left: 56px;
  line-height: 70px;
}

.page li a {
  font-size: 16px;
  color: #505050;
}
.page .fr {
  float: right;
}
.page .fr span {
  float: left;
  height: 34px;
  margin-top: 18px;
  text-align: center;
  line-height: 34px;
  border-radius: 17px;
  cursor: pointer;
}

.page .fr span:first-of-type {
  width: 120px;
  background-color: #2a82e4;
  color: #fff;
}

.page .fr span:last-of-type {
  width: 70px;
  border: #a6a6a6 solid 1px;
  margin-left: 16px;
  color: #383838;
}

.pro_topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.9);
}

// 右侧登录部分
.user {
  float: right;
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  .user-head {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 10px;
    border: 1px solid #e5e5e5;
  }
  .user-phone {
    font-size: 12px;
  }
  .user-list {
    position: absolute;
    left: calc(50% + 10px);
    top: 60%;
    transform: translateX(-50%);
    width: 120px;
    background: #fff;
    display: none;
    color: #666;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.157143);
    .user-list-item {
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      cursor: pointer;
      .el-icon-switch-button {
        font-size: 13px;
        padding-left: 5px;
        font-weight: 600;
      }
      &:hover {
        background: #2a82e4 !important;
        color: #fff !important;
      }
    }
    .logout {
      background: #eee;
    }
  }
  &:hover {
    .user-list {
      display: block;
    }
  }
}
</style>
