/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * 大写首字符
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * 验证手机号
 * @param str 号码输入input
 * @returns {boolean} true为正确
 */
export function checkTel(str) {
  let reg = /^1[23456789][0-9]{9}$/
  if (reg.test(str)) return true
  return false
}

/**
 * 验证仅存在数字及字母
 * @param str 验证文本
 * @returns {boolean} true为正确
 */
export function checkPassWord(str) {
  let reg = /^[A-Za-z0-9]+$/
  if (reg.test(str)) return true
  return false
}
/*
 * 时间转年月日
 * @param {String} val
 */
export const getTimePicker = val => {
  if (val) {
    let date = new Date(val)
    let year = date.getFullYear() // 获取完整的年份(4位,1970)
    let month = date.getMonth() + 1 // 获取月份(0-11,0代表1月,用的时候记得加上1)
    let day = date.getDate() // 获取日(1-31)
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }

    return `${year}-${month}-${day}`
  } else {
    return ``
  }
}

/**
 * 时间戳转时间（年|月|日 时:分:秒）
 * @param {timestamp} value
 */
export const setTimeFilter = val => {
  if (val) {
    let date = new Date(val * 1000)
    let year = date.getFullYear() // 获取完整的年份(4位,1970)
    let month = date.getMonth() + 1 // 获取月份(0-11,0代表1月,用的时候记得加上1)
    let day = date.getDate() // 获取日(1-31)
    let hour = date.getHours() // 获取小时数(0-23)
    let minute = date.getMinutes() // 获取分钟数(0-59)
    let second = date.getSeconds() // 获取秒数(0-59)
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (hour < 10) {
      hour = '0' + hour
    }
    if (minute < 10) {
      minute = '0' + minute
    }
    if (second < 10) {
      second = '0' + second
    }

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }
}
