<template>
  <!-- 找回密码 -->
  <div class="password" v-if="!success">
    <div class="main">
      <span class="title">重置密码</span>
      <!-- <span class="back" @click="$emit('update:status', 1)">返回登录</span> -->
      <div class="input-item">
        <span class="item-icon iconfont icon-shoujihaoma"></span>
        <input
          class="input"
          type="text"
          placeholder="请输入手机号"
          v-model="phone"
          autocomplete="off"
          @input="edit('phone')"
          @keyup.enter="inputSubmit"
          maxlength="11"
          @focus="changeClass($event, 'add')"
          @blur="changeClass($event, 'del')"
        />
      </div>
      <div class="code">
        <div class="input-item code-input">
          <span class="item-icon iconfont icon-yanzheng"></span>
          <input
            class="input"
            type="text"
            placeholder="验证码"
            v-model="code"
            autocomplete="off"
            @input="edit('code')"
            @keyup.enter="inputSubmit"
            maxlength="4"
            @focus="changeClass($event, 'add')"
            @blur="changeClass($event, 'del')"
          />
        </div>
        <el-button
          class="code-btn"
          :class="phone && phone.length == 11 ? '' : 'input-default'"
          type="primary"
          round
          v-show="count <= 0"
          :loading="loading"
          @click="getCode"
          >{{ loading ? '' : '获取验证码' }}</el-button
        >
        <div class="code-default" v-show="count > 0">已发送 ({{ count }}S)</div>
      </div>
      <div class="input-item">
        <span class="item-icon iconfont icon-mima2"></span>
        <input
          class="input"
          :type="passwordType ? 'password' : 'text'"
          placeholder="请设置6-15位密码"
          v-model="password"
          :autocomplete="passwordType ? 'new-password' : 'off'"
          @keyup.enter="inputSubmit"
          :maxlength="16"
          @focus="changeClass($event, 'add')"
          @blur="changeClass($event, 'del')"
        />
        <span
          class="input-icon"
          v-show="password"
          @click="passwordType = !passwordType"
        >
          <span
            class="password-icon iconfont icon-yincang"
            v-show="passwordType"
          ></span>
          <span
            class="password-icon iconfont icon-xianshi"
            v-show="!passwordType"
          ></span>
        </span>
      </div>
      <el-button
        class="login"
        v-show="password && phone && code"
        :loading="submitLoading"
        @click="submit"
        >确定重置</el-button
      >
      <div
        class="login login-disable"
        v-show="!password || !phone || !code"
        @click="submit"
      >
        确定重置
      </div>
      <div class="bottom">
        想起密码?
        <span class="register" @click="$emit('update:status', 1)"
          >直接登录</span
        >
      </div>
    </div>
  </div>
  <div class="success" v-else>
    <img class="success-img" :src="img" :key="img" alt="" />
    <span class="success-title">重置密码成功!</span>
    <span class="success-tips">密码重置成功，现在就去登录查看活动模型吧</span>
    <el-button
      class="success-btn"
      type="primary"
      round
      @click="$emit('update:status', 1)"
      >立即登录</el-button
    >
  </div>
</template>

<script>
import { checkTel, checkPassWord } from '@/filters/index'
import SvgIcon from '@/components/SvgIcon/index'
import { loginUrl } from '../../../utils/imagesUrl'

export default {
  name: '',
  props: {
    status: Number,
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      phone: '', //手机号
      password: '', //密码
      code: '', //验证码
      loading: false, //获取验证码loading
      count: 0, //倒计时秒
      codeTime: '', //倒计时计时器
      read: false, //true 协议已读
      submitLoading: false,
      passwordType: true, // 密码显示 true密文 false明文
      success: false,
      img: loginUrl.login_retrieve_success,
    }
  },
  methods: {
    edit(type) {
      if (type == 'phone') {
        this.phone = this.phone.replace(/[^\d]/g, '')
      }
      if (type == 'code') {
        this.code = this.code.replace(/[^\d]/g, '')
      }
    },
    changeClass(e, type) {
      if (type == 'add') {
        e.currentTarget.parentElement.classList.add('input-focus')
      } else {
        e.currentTarget.parentElement.classList.remove('input-focus')
      }
    },
    // 输入框按下回车
    inputSubmit() {
      // || !this.read
      if (!this.phone || !this.code || !this.password) return
      this.submit()
    },
    async goAgreement() {
      this.read = true
      let url = await this.$store.state['common'].domainList.www.url
      window.open(`${url}/vuemerchant/#/agreement`)
    },
    // 获取验证码
    async getCode() {
      if (!this.phone || !checkTel(this.phone))
        return this.$message.warning('请输入正确的手机号')
      if (this.count) return
      // 验证腾讯防水墙
      const res = await this.$store.dispatch('common/getTencentCheck')
      if (res.ret) return
      this.loading = true
      try {
        // 获取验证码
        const result = await this.$store.dispatch('common/getCode', {
          number: this.phone,
          cms_type: 0,
          ticket: res.ticket,
          rand_str: res.randstr,
        })
        this.count = 0
        if (this.codeTime) clearInterval(this.codeTime)
        this.count = 60
        this.codeTime = setInterval(() => {
          this.count = this.count - 1
          if (this.count <= 0) {
            clearInterval(this.codeTime)
          }
        }, 1000)
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 提交重置密码
    async submit() {
      if (!checkTel(this.phone))
        return this.$message.warning('请输入正确的手机号')
      if (!this.code || this.code.length < 4)
        return this.$message.warning('请输入正确的验证码')
      if (!this.password) return this.$message.warning('请输入需要设置的密码')
      // if (!checkPassWord(this.password)) return this.$message.warning('密码仅支持输入数字及字母')
      // if (!this.read) return this.$message.warning('请先阅读并同意平台协议');

      this.submitLoading = true
      try {
        const params = {
          mobile: this.phone,
          code: this.code,
          new_pwd: this.password,
          new_pwd_repeat: this.password,
        }
        const result = await this.$store.dispatch(
          'merchant/userRetrieve',
          params
        )
        if (result.status == 0) {
          this.success = true
        }
        this.submitLoading = false
      } catch (error) {
        this.submitLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  border: none;
  outline: none;
  background: none;
}

input::placeholder {
  line-height: 20px;
  font-size: 14px;
  /* Mozilla Firefox 4 to 18 */
  color: #aebac2;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.input-focus {
  border: 1px solid #3af !important;
  box-shadow: 0 0 0 2px #ebf7ff;
}
.input-default {
  border: 1px solid #adddff !important;
  background: #adddff !important;
}
.password {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;

  .main {
    display: flex;
    flex-direction: column;
    width: 318px;
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      color: #0e1234;
    }

    .input-item {
      display: flex;
      overflow: hidden;
      position: relative;
      align-items: center;
      box-sizing: border-box;
      margin-top: 24px;
      padding: 10px 0;
      border: 1px solid #cbcbcb;
      border-radius: 6px;
      height: 44px;
      background: #fff;
      line-height: 44px;
      transition: all 0.3s;
      &:hover {
        border: 1px solid #3af;
      }

      .item-icon {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 40px;
        text-align: center;
        font-size: 16px;
        color: #333;
        &::after {
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          border: 1px solid #d8d8d8;
          height: 16px;
          content: '';
          transform: translateY(-50%);
        }
        .item-icon-svg {
          width: 14px;
          height: 14px;
        }
      }
      .input {
        padding: 20px;
        width: 100%;
        height: 100%;
      }
      .input-icon {
        display: inline-block;
        padding-right: 20px;
        height: 44px;
        cursor: pointer;
        line-height: 44px;
        text-align: left;
        .password-icon {
          font-size: 16px;
          color: #333;
        }
      }
    }
    .code {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      .code-input {
        margin-right: 8px;
        margin-top: 0;
      }
      .code-btn {
        padding: 14px 19px;
        border-radius: 6px;
        width: 108px;
        height: 44px;
        background-color: #3af;
        color: #f7f9fa;
        &:hover {
          background-color: #248bf2;
        }
        &:active {
          background-color: #176de6;
        }
        /deep/ .el-icon-loading {
          margin-left: 6px;
        }
      }
      .code-default {
        border-radius: 6px;
        width: 110px;
        height: 44px;
        background-color: #adddff;
        line-height: 44px;
        text-align: center;
        color: #f7f9fa;
      }
    }
    .login {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 32px;
      border-radius: 6px;
      height: 44px;
      background: #3af;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      color: #f7f9fa;
      border:none;
      &:hover {
        background-color: #248bf2;
      }
      &:active {
        background-color: #176de6;
      }
    }
    .login-disable {
      background: #adddff !important;
      color: #f7f9fa;
    }

    .bottom {
      padding-top: 91px;
      text-align: center;
      color: #72838f;
      .register {
        display: inline-block;
        padding: 0 6px;
        border-radius: 2px;
        height: 24px;
        cursor: pointer;
        line-height: 24px;
        font-weight: bold;
        color: #3af;
      }
    }
  }
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 70px;
  .success-img {
    display: block;
    width: 320px;
    height: 203px;
  }
  .success-title {
    padding-top: 30px;
    line-height: 22px;
    font-weight: bold;
    font-size: 22px;
    color: 0e1234;
  }
  .success-tips {
    display: block;
    padding: 10px 0 40px;
    line-height: 14px;
    font-size: 14px;
    color: 172838f;
  }
  .success-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    width: 318px;
    height: 44px;
    background: #3af;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #f7f9fa;
    &:hover {
      background-color: #248bf2;
    }
    &:active {
      background-color: #176de6;
    }
  }
}
</style>
